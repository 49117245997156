<template>
  <div>
    <public-header mode="transparent" />
    <div class="page-header page-header-small">
      <div
        class="page-header-image"
        data-parallax="true"
      />
      <div class="content-center mt-4">
        <div class="container">
          <h1 class="title h1">
            Pricing
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="priceTopContent my-5">
        <div class="container">
          <div class="section-subtitle text-center mb-4">Secure & Flexible Payment Options</div>
          <div class="section-title text-center mb-5">It's Easy to Get Started with Let's Connect</div>
          <div class="text-center">You can get started in three simple steps. Create a FREE account, select a subscription, and begin sending and receiving text messages.</div>
        </div>
      </div>

      <div class="pricing-section">
        <div class="plans-section" />
        <div class="boost-plan">
          <div class="container-fluid">
            <div class="row no-gutters">
              <div class="col text-center">
                <h2>Boost Your Plan with Extra Credits</h2>
                <p>All plans include 200 credits per month. Need More? Add additional credits at any time.</p>
                <div class="d-flex justify-content-center align-items-center">
                  <p>
                    <span>SMS TEXT</span>
                    = 1 credit / contact
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>  MMS TEXT</span>
                    = 3 credits / contact
                  </p>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col credit-table">
                <div class="container">
                  <table class="fs-es border-styles text-center">
                    <tbody>
                      <tr class="bg-dark text-white">
                        <th data-pricing-toggle="Monthly Credits">
                          Annual Credits
                        </th><th data-pricing-toggle="Monthly Cost">
                          Annual Cost
                        </th><th>Cost Per Credit</th>
                      </tr><tr>
                        <td data-pricing-toggle="200 credits">
                          2,400 credits
                        </td><td>Included</td><td>$0.040</td>
                      </tr><tr>
                        <td data-pricing-toggle="500 credits">
                          6,000 credits
                        </td><td data-pricing-toggle="+11.40">
                          +$118.80
                        </td><td data-pricing-toggle="$0.038">
                          $0.033
                        </td>
                      </tr><tr>
                        <td data-pricing-toggle="1,500 credits">
                          18,000 credits
                        </td><td data-pricing-toggle="+$49.40">
                          +$483.60
                        </td><td data-pricing-toggle="$0.038">
                          $0.031
                        </td>
                      </tr><tr>
                        <td data-pricing-toggle="2,500 credits">
                          30,000 credits
                        </td><td data-pricing-toggle="+$87.40">
                          +$828.00
                        </td><td data-pricing-toggle="$0.038">
                          $0.030
                        </td>
                      </tr><tr>
                        <td data-pricing-toggle="5,000 credits">
                          60,000 credits
                        </td><td data-pricing-toggle="+$172.80">
                          +$1,670.40
                        </td><td data-pricing-toggle="$0.036">
                          $0.029
                        </td>
                      </tr><tr>
                        <td data-pricing-toggle="10,000 credits">
                          120,000 credits
                        </td><td data-pricing-toggle="+$333.20">
                          +$3,292.80
                        </td><td data-pricing-toggle="$0.034">
                          $0.028
                        </td>
                      </tr><tr>
                        <td
                          data-pricing-toggle="Need more than this? Call us."
                          colspan="3"
                        >
                          Need more than this? Call us.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row no-gutter">
              <div class="col">
                <div class="container">
                  <div class="feature-opt">
                    <div class="row pt-5 no-gutters">
                      <div class="col text-center">
                        <h2>Upgrade Your Plan with Optional Features</h2>
                      </div>
                    </div>
                    <div class="row py-3 no-gutters">
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center px-2 py-3">
                        <p class="bg-dark text-white bg-border fw-600 py-2">
                          High-Volume Number
                        </p>
                        <div class="py-4 bg-image">
                          <img
                            alt="High Volume Number Icon"
                            class="py-2"
                            src="../../../assets/img/Icon_High-Speed-Number.svg"
                            width="70"
                          >
                          <p>$25 / mo</p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center px-2 py-3">
                        <p class="bg-dark text-white bg-border fw-600 py-2">
                          Additional Numbers
                        </p>
                        <div class="py-4 bg-image">
                          <img
                            alt="High Volume Number Icon"
                            class="py-2"
                            src="../../../assets/img/Icon_Addtl-Number.svg"
                            width="70"
                          >
                          <p>$25 / number / mo</p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center px-2 py-3">
                        <p class="bg-dark text-white bg-border fw-600 py-2">
                          Additional Users
                        </p>
                        <div class="py-4 bg-image">
                          <img
                            alt="High Volume Number Icon"
                            class="py-2"
                            src="../../../assets/img/Icon_Addtl-User.svg"
                            width="70"
                          >
                          <p>$20 / users / mo</p>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center px-2 py-3">
                        <p class="bg-dark text-white bg-border fw-600 py-2">
                          Additional Keywords
                        </p>
                        <div class="py-4 bg-image">
                          <img
                            alt="High Volume Number Icon"
                            class="py-2"
                            src="../../../assets/img/Icon_Addtl-Keyword.svg"
                            width="70"
                          >
                          <p>$25 / keywords / mo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lets-connect-feature">
        <div class="container">
          <div class="row no-gutters py-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <img
                alt="Graphic_Shortcode"
                class="feature_img text-right"
                src="../../../assets/img/Graphic_Shortcode.png"
              >
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <div class="p-5">
                <h2>Do More with a Dedicated Short Code!</h2>
                <div>
                  <p class="py-1">
                    A dedicated short code is a 5- or 6-digit textable number reserved for exclusive use by one business.
                  </p>
                  <ul class="list pl-4">
                    <li class="pb-1">
                      Easy to Remember
                    </li><li class="pb-1">
                      Access to Unlimited Keywords
                    </li><li class="pb-1">
                      Perfect for High-Volume Messaging
                    </li>
                  </ul>
                  <button
                    type="submit"
                    class="btn text-white btn-primary"
                    value="learn more"
                  >
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters py-5 ft-middle">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <div class="p-5">
                <h2>Make Opt Ins Easy with Keywords</h2>
                <div>
                  <p class="py-1">
                    Every Lets Lets_Connect_with_text plan comes with one or more Keywords - unique terms that can help you supercharge list growth.
                  </p>
                  <ul class="list pl-4">
                    <li class="pb-1">
                      Easy to Remember
                    </li><li class="pb-1">
                      Access to Unlimited Keywords
                    </li><li class="pb-1">
                      Perfect for High-Volume Messaging
                    </li>
                  </ul>
                  <button
                    type="submit"
                    class="btn text-white btn-primary"
                    value="learn more"
                  >
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <img
                alt="Graphic_Shortcode"
                class="feature_img text-left"
                src="../../../assets/img/Graphic_Shortcode.png"
              >
            </div>
          </div>
          <div class="row no-gutters py-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <img
                alt="Graphic_Shortcode"
                class="feature_img text-right"
                src="../../../assets/img/Graphic_Shortcode.png"
              >
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-5 my-auto">
              <div class="p-5">
                <h2>Scalable & Enterprise Solutions</h2>
                <div>
                  <p class="py-1">
                    High-volume text message campaigns are easy with pricing plans tailored to your needs.
                  </p>
                  <ul class="list pl-4">
                    <li class="pb-1">
                      Easy to Remember
                    </li><li class="pb-1">
                      Access to Unlimited Keywords
                    </li><li class="pb-1">
                      Perfect for High-Volume Messaging
                    </li>
                  </ul>
                  <button
                    type="submit"
                    class="btn text-white btn-primary"
                    value="learn more"
                  >
                    TALK TO AN EXPERT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="free-section d-flex align-items-center justify-content-center">
        <div class="container-fluid">
          <div class="row no-gutters">
            <div class="col">
              <div class="free_text text-center">
                <h3 class="font-weight-bold">
                  Join the 165,000 customers who have used SMS marketing to connect with their audiences
                </h3>
                <button
                  type="submit"
                  class="btn text-white btn-primary"
                  value="Get Started for Free"
                >
                  Try it for free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper plansPage">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="plansTableWrapper">
              <div class="plansTable">
                <div class="typeSelectorWrapper">
                  <div
                    :class="_getCLassName('sms')"
                    @click="updateSelectedPlanType('sms')"
                  >
                    <img
                      src="https://www.clicksend.com/img/ic-shared_sms_circle_shadow.svg"
                      alt="img"
                      class="typeIcon"
                    >
                    <p class="typeLabel">
                      SMS
                    </p>
                  </div>
                  <div
                    :class="_getCLassName('mms')"
                    @click="updateSelectedPlanType('mms')"
                  >
                    <img
                      src="https://www.clicksend.com/img/ic-shared_mms_circle_shadow.svg"
                      alt="img"
                      class="typeIcon"
                    >
                    <p class="typeLabel">
                      MMS
                    </p>
                  </div>
                </div>
                <div class="typeSelectionBody">
                  <h3 class="typeSelectorLabel">
                    {{ selectedPlanType.toUpperCase() }}
                  </h3>
                  <div class="typeSelectionBodyInnerWrapper">
                    <div class="outboundWrapper">
                      <h4>OUTBOUND</h4>
                      <div class="outBoundTableWrapper">
                        <div class="outboundTableHeader">
                          <div class="cell">
                            ESTIMATED MESSAGES
                          </div>
                          <div class="cell">
                            PRICE / MESSAGE
                          </div>
                        </div>
                        <div
                          v-for="plan in allPlans[selectedPlanType]"
                          :key="plan.index"
                          class="outboundTableRow"
                        >
                          <div class="cell">
                            Approx {{ plan.estimattedMessages }}
                          </div>
                          <div class="cell">
                            ${{ plan.pricePerMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="priceBeat">
                        We'll beat any cheaper comparable quotes.
                      </div>
                    </div>
                    <div class="inboundWrapper">
                      <h4>INBOUND</h4>
                      <h5>Always Free</h5>
                      <p>
                        <i
                          class="fa fa-check greenText"
                          aria-hidden="true"
                        />
                        Replies are supported with our free shared number or via a dedicated number.
                      </p>
                      <h4>DEDICATED NUMBER</h4>
                      <h5>No Setup Fees</h5>
                    </div>
                  </div>
                  <button
                    v-if="isUserLoggedIn"
                    class="getSTartedBtn"
                    @click="$router.push('/pages/dashboard')"
                  >
                    Goto dashboard
                  </button>
                  <button
                    v-if="!isUserLoggedIn"
                    class="getSTartedBtn"
                    @click="$router.push('/register')"
                  >
                    Get Started Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="container featuresWrapper">
              <div class="row">
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-people_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    Unlimited users/contacts
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-lifesaver_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    Free 24/7 support
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-gauge_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    Lightning fast delivery
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-check_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    We will beat any quote
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-globe_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    Global coverage
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-up_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    100% Uptime SLA
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-time_settings_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    No setup fees
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-doc_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    No contracts
                  </p>
                </div>
                <div class="col-md-4 featureWrapper">
                  <img
                    src="../../../assets/img/ic-shared-piemoney_circle.svg"
                    alt="alt"
                    class="featureLogo"
                  >
                  <p class="featureText">
                    No monthly fees
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="container questionsWrapper">
              <div class="row">
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. How many characters can I send in 1 Message?
                  </h3>
                  <p class="answer">
                    A standard SMS message has a maximum of 160 characters
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. Do you allow multiple users to share the same account?
                  </h3>
                  <p class="answer">
                    Yes! You can add/manage as many sub accounts as you like.
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. Do you offer postpaid plans?
                  </h3>
                  <p class="answer">
                    Yes! The pricing is exactly the same. With prepaid, you can use your credit card to recharge, with postpaid we send you an invoice each month for messages sent.
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. Can you send us a tax invoice?
                  </h3>
                  <p class="answer">
                    Yes! If you’re on prepaid, we automatically send you a tax invoice after each transaction. If you’re on postpaid, we send you an invoice at the end of each month
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. Are there any setup or monthly fees?
                  </h3>
                  <p class="answer">
                    Definitely not! We only charge you for what you use.
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. What are the payment options?
                  </h3>
                  <p class="answer">
                    We accept credit card and bank transfer. Let us know if you’d like to pay another way.
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. How long will it take to setup my account?
                  </h3>
                  <p class="answer">
                    No time! All accounts have instant setup. Be up and running in minutes
                  </p>
                </div>
                <div class="col-md-6 questionWrapper">
                  <h3 class="question">
                    Q. What are your refund policies?
                  </h3>
                  <p class="answer">
                    In line with our commitment to customer satisfaction, we have one of the most liberal refund policies around. If you request a refund within 14 days of making any form of payment, you will get a full refund, no questions asked. After 14 days, refunds will be considered on a case-by-case basis. Fair?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="standerdFeatureWrapper">
        <div class="standerdFeatureHeaderImageWrapper">
          <img
            src="https://www.clicksend.com/img/ic-shared_sms_pinkish.svg"
            alt="alt"
          >
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4>Standard Features Included</h4>
              <div class="container standerdFeatureInnerWrapper">
                <div class="row">
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>Reliable – 100% uptime SLA guarantee</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-lifesaver.svg"
                      alt="alt"
                    >
                    <p>Amazing free 24/7 support</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-guage2.svg"
                      alt="alt"
                    >
                    <p>Lightning fast message delivery,</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-rocket.svg"
                      alt="alt"
                    >
                    <p>High performance – 4800 messages per minute</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bar1.svg"
                      alt="alt"
                    >
                    <p>Guaranteed delivery with Tier 1 networks</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-globe.svg"
                      alt="alt"
                    >
                    <p>Send SMS online to all countries globally</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-note.svg"
                      alt="alt"
                    >
                    <p>Customize sender ID</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-pie1.svg"
                      alt="alt"
                    >
                    <p>Real-time message status monitoring</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Secure 256-bit SSL</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Long 1224 character limit</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our SMS portal</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-xls.svg"
                      alt="alt"
                    >
                    <p>Upload from CSV or Excel file for bulk SMS campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>We automatically handle opt-outs for marketing campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-person.svg"
                      alt="alt"
                    >
                    <p>Free fixed number</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-back.svg"
                      alt="alt"
                    >
                    <p>Free replies</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting1.svg"
                      alt="alt"
                    >
                    <p>No setup fees</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-check.svg"
                      alt="alt"
                    >
                    <p>No hidden charges</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our online SMS software</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bell.svg"
                      alt="alt"
                    >
                    <p>Free low credit warning</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-card.svg"
                      alt="alt"
                    >
                    <p>Optional auto-topup</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-up.svg"
                      alt="alt"
                    >
                    <p>Free delivery reports</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting2.svg"
                      alt="alt"
                    >
                    <p>Free customization</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-play.svg"
                      alt="alt"
                    >
                    <p>No setup required. Be up and running in minutes.</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-wallet.svg"
                      alt="alt"
                    >
                    <p>Flexible prepaid and postpaid payment options available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomQuestionsWrapper">
          <h2>Questions</h2>
          <p>Free accounts and free advice. We can’t wait to see what you create.</p>
          <button
            v-if="isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/pages/dashboard')"
          >
            Goto dashboard
          </button>
          <button
            v-if="!isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/register')"
          >
            Get Started Now!
          </button>
        </div>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script>
  import { plans } from '../../../static-data/plans'
  import { faqData } from '../../../static-data/faq-data'

  export default {
    name: 'PlansPage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
      isAccordianVisible: false,
      allPlans: {},
      faqDataSet: null,
      selectedPlanType: 'sms',
    }),
    computed: {},
    created () {
      this.faqDataSet = faqData
      this.allPlans.sms = plans.SMS.map((p, index) => ({
        estimattedMessages: p.primaryHeading.split(' ')[0],
        pricePerMessage: `${(p.secondaryHeading.split(' per ')[0]).split(' ')[1]}`,
        index,
      }))
      this.allPlans.mms = plans.MMS.map((p, index) => ({
        estimattedMessages: p.primaryHeading.split(' ')[0],
        pricePerMessage: `${(p.secondaryHeading.split(' per ')[0]).split(' ')[1]}`,
        index,
      }))
      this.checkIfUserLoggedIn()
    },
    methods: {
      updateSelectedPlanType (selectedPlanType) {
        this.selectedPlanType = selectedPlanType
      },
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },

      redirectToPage (path) {
        window.location = window.location.origin + path
      },
      _getCLassName (type) {
        if (this.selectedPlanType === type) {
          return 'typeWrapper selected'
        } else {
          return 'typeWrapper'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
* {
  transition: 0.3s;;
}
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  // background-image: radial-gradient(circle 1693px at 100% 100%, var(--clr-secondary) 0%, var(--clr-secondary) 33%, var(--clr-accent-dark) 66%);
  background-color: var(--clr-accent);
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  height: 40vh;
}
.getStartedButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .getStarted {
    margin-top: 15px;
    background-color: rgb(233, 178, 0);
    color: rgb(255, 255, 255);
    padding: 16px 52px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 6px;
  }
}
.serviceCardOuterWrapper {
  padding: 15px 36px;
  .serviceCardInnerWrapper {
    height: 400px;
    box-shadow: 0px 0px 100px rgba(0,0,0,0.15);
    border-radius: 6px;
    background-color: white;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .serviceCardIconImg {
      width: 130px;
      height: 130px;
    }
    .cardHeader {
      margin-top: 35px;
      font-size: 1.3rem;
    }
    .smsCardSubHeader {
      text-align: center;
      font-size: 0.9rem;
      color: grey;
    }
    .cardActionBtn {
      margin-top: 35px;
      cursor: pointer;
      padding: 6px 30px;
      font-size: 0.9rem;
      border: 1px solid rgb(0, 128, 255);
      border-radius: 6px;
      box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
    }
    &.smsService {
      .cardActionBtn {
        border: 1px solid #61BCF5;
        &:hover {
          background-color: #61BCF5;
          color: white;
        }
      }
    }
    &.mmsService {
      .cardActionBtn {
        border: 1px solid #420FB7;
        &:hover {
          background-color: #420FB7;
          color: white;
        }
      }
    }
    &.rmService {
      .cardActionBtn {
        border: 1px solid #FF6A4B;
        &:hover {
          background-color: #FF6A4B;
          color: white;
        }
      }
    }
    &.voiceService {
      .cardActionBtn {
        border: 1px solid #FC6DE5;
        &:hover {
          background-color: #FC6DE5;
          color: white;
        }
      }
    }
  }
}

table {
  border: 1px solid #ccc;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
}

table {
  width: 100%;
}

tr {
  position: relative;
}

tr:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  left: 0px;
  bottom: -2px;
  background: radial-gradient(ellipse at center,#c7c9c7 0,#c7c9c7 27%,transparent 27%,transparent 100%) 0 bottom repeat-x;
  background-size: 4px 4px;
}

th, td {
  padding: 10px 6px;
}

th:first-child {
  border-radius: 5px 0 0 0;
}

th:last-child {
  border-radius: 0 5px 0 0;
}

tbody {
  border-top: 1px solid #ccc;
}

.bg-dark {
  background-color: #990000 !important;
}

.bg-image {
  background: white;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
}

.bg-border {
  border-radius: 5px 5px 0 0;
}

.feature-opt {
  p{
    margin: 0px !important;
    font-size: 1rem !important;
    font-weight: 600;
  }
}

.pricing-section {
  background: #f2f2f2;
}

.lets-connect-feature {
  img {
    width: 100%;
    height: auto;
  }
  h2 {
    font-size: 1.85rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  p, ul {
    font-size: 1.25rem;
  }
  button {
    text-transform: uppercase;
    min-width: 15rem;
    padding: .75rem 1rem;
    font-size: 1rem;
    border-radius: 3rem;
    font-weight: 600;
  }
}

.pricing_header {
  min-height: 500px;
  background-color: #f1b2548a;
  position: relative;
  z-index: 1;
  .pricing_text {
    margin-top: 5rem;
    .title {
      color: #373a36;
      text-align: center;
      font-weight: 700;
      margin-top: 0;
      font-size: 3.5rem;
      line-height: 3.125rem;
    }
    .subtitle {
      color: #373a36;
      font-size: 1.75rem;
      text-align: center;
      line-height: 1.75rem;
      font-weight: 400;
      margin-top: 0;
    }
  }
}

.boost-plan {
  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.35rem;
    line-height: 1.61em;
    margin: 0px 10px 10px 10px;
  }
  span {
    font-weight: 700;
    color: #f9a932
  }
}

.free-section {
  background-color: #990000;
  color: #fff;
  min-height: 300px;
  button {
    text-transform: uppercase;
    min-width: 15rem;
    padding: .75rem 1rem;
    font-size: 1rem;
    border-radius: 3rem;
    font-weight: 600;
  }
}

@media (max-width: 768.98px) and (min-width: 425px) {
  .pricing_header {
    min-height: 350px;
    background-color: #f1b2548a;
    position: relative;
    z-index: 1;
    .pricing_text {
      margin-top: 5rem;
      .title {
        color: #373a36;
        text-align: center;
        font-weight: 700;
        margin-top: 0;
        font-size: 2.5rem;
        line-height: 1.125rem;
      }
      .subtitle {
        color: #373a36;
        font-size: 1.25rem;
        text-align: center;
        line-height: 0.75rem;
        font-weight: 400;
        margin-top: 0;
      }
    }
  }

  .free-section {
    min-height: 250px;
    h3 {
      font-size: 1.5rem;
    }
    button {
      text-transform: uppercase;
      min-width: 12rem;
      padding: .75rem 1rem;
      font-size: .75rem;
      border-radius: 3rem;
      font-weight: 600;
    }
  }

  .boost-plan {
    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
      line-height: 1.61em;
      margin: 0px 10px 10px 10px;
    }
    span {
      font-weight: 700;
      color: #f9a932
    }
  }

  .pricing-section {
    padding: 40px 0px !important;
  }
}

@media (max-width: 424.98px) {
  .ft-middle {
    flex-direction: column-reverse;
  }

  .pricing_header {
    min-height: 250px;
    background-color: #f1b2548a;
    position: relative;
    z-index: 1;
    .pricing_text {
      margin-top: 5rem;
      .title {
        color: #373a36;
        text-align: center;
        font-weight: 700;
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 0.125rem;
      }
      .subtitle {
        color: #373a36;
        font-size: 0.75rem;
        text-align: center;
        line-height: 0.125rem;
        font-weight: 400;
        margin-top: 0;
      }
    }
  }

  .free-section {
    min-height: 200px;
    h3 {
      font-size: 1rem;
      margin-bottom: 15px;
    }
    button {
      text-transform: uppercase;
      min-width: 10rem;
      padding: .75rem 1rem;
      font-size: .5rem;
      border-radius: 3rem;
      font-weight: 600;
    }
  }

  .boost-plan {
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.75rem;
      line-height: 1.61em;
      margin: 0px 10px 10px 10px;
    }
    span {
      font-weight: 700;
      color: #f9a932
    }
  }

  .pricing-section {
    padding: 40px 0px !important;
  }
}

.pricingWrapper {
  // background-color: rgb(233, 178, 0);
  background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-abstract-line-background-design-image_351709.jpg');
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .pricingIconWrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    border-radius: 60px;
    color: rgb(233, 178, 0)!important;
    * {
      color: rgb(233, 178, 0)!important;
    }
    font-size: 2.3rem;
  }
  .pricingHeader {
    font-size: 2rem;
    margin-top: 10px;
    color: white;
  }
  .viewPlansBtn {
    margin-top: 35px;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 0.9rem;
    color: white;
    background-color: rgb(219, 186, 0);
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
  }
}
.plansPage {
  // padding-top: 10rem;
  .plansWrapper {
    width: 100%;
    background-color: rgb(234, 234, 234);
    min-height: 800px;
    border-radius: 16px;
    .planTableHeader {
      background-color: rgb(38, 43, 47);
      min-height: 300px;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h3 {
        margin-top: 2rem;
        font-size: 3rem;
        color: white;
      }
      .typeSwitcher {
        * {
          transition: 0.3s;
        }
        margin-top: 3rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .type {
          margin: 0 5rem;
          padding: 0 1rem;
          font-size: 1.5rem;
          color: rgba(255, 255, 255, 0.238);
          cursor: pointer;
          padding-bottom: 10px;
          border-bottom: 2px solid rgba(31, 31, 31, 0);
          &.active {
            color: white!important;
            border-bottom: 2px solid rgb(250, 183, 0)!important;
          }
          &:hover {
            color: rgba(255, 255, 255, 0.353);
            border-bottom: 2px solid rgba(250, 183, 0, 0.197);
          }
        }
      }
    }
  }
  .pricesWrapper {
  background-color: rgb(245, 245, 245);
  margin-top: 10px;
  padding-top: 40px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.priceCardOuterWrapper {
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceCardInnerWrapper {
  width: 250px;
  height: 300px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.13);
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  flex-direction: column;
}
.priceCardInnerWrapper:hover {
  border: 2px solid white;
  background-color: rgb(255, 184, 52);
}
.priceHeading {
  font-size: 1.4rem;
  font-weight: 600;
}
.priceSubHeading {
  margin-top: -15px;
  font-size: 0.9rem;
  font-weight: 400;
}
.priceLabel {
  font-size: 2.3rem;
  font-weight: 600;
}
.priceSelectBtn {
  margin-top: 30px;
  border: 1px solid rgb(255, 184, 52);
  padding: 8px 58px;
}
.priceCardInnerWrapper:hover .priceSelectBtn {
  margin-top: 30px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.13);
  background-color: white;
  transition: 0.4s;
  padding: 8px 58px;
}
.priceCardInnerWrapper:hover .priceSelectBtn:hover {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0);
}
}
// .page-header-image {
//         background-image: linear-gradient(47deg,#f9b25e,#f72ba6);

// }
.page-header:before {
    background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
    max-height: unset !important;
    min-height: unset !important;
    // height: 400px !important;
}
.plansTableWrapper {
  text-align: center;;
  margin-top: 0rem;
  min-height: 400px;
    width: 100%;
h3 {
  font-size: 3rem;
  color: white;
  margin-bottom: 80px;
}
  .plansTable {
  margin: auto;
    width: 90%;
  border-radius: 12px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
  min-height: 400px;

    background-color: white;
  }
}
.typeSelectorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;
  .typeWrapper {
    padding: 15px 20px;
    cursor: pointer;
    .typeIcon {
      width: 100px;
      margin-bottom: 10px;
    }
    .typeLabel {
      font-size: 1rem;
    }
    border-bottom: 2px solid rgb(255, 255, 255);
    &:hover {
      border-bottom: 2px solid rgb(201, 218, 253);
    }
    &.selected {
      border-bottom: 2px solid rgb(117, 25, 255)!important;
    }
  }
}
    .typeSelectionBody {
      .typeSelectorLabel {
        color: black;
        margin-top: 1.5rem;
        margin-bottom: 0rem;
        font-size: 2.1rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .typeSelectionBodyInnerWrapper {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media screen and (max-width: 768px) {
          align-items: center;
          flex-direction: column;
        }
        .outboundWrapper {
          flex: 1;
          .outboundTableHeader {
            display: flex;
            padding: 12px 0;
            font-size: 0.9rem;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            justify-content: space-evenly;
            align-items: center;
            background-color: rgb(0, 85, 255);
            .cell {
              color: white
            }
          }
          .outboundTableRow {
            border-top: 1px solid rgba(54, 161, 255, 0.346);
            display: flex;
            padding: 12px 0;
            font-size: 0.9rem;
            justify-content: space-evenly;
            align-items: center;
            background-color: rgba(172, 214, 250, 0.306);
            .cell {
              color: rgb(41, 130, 255);
            }
          }
        }
        .inboundWrapper {
          padding: 0 30px;
          flex: 1;
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 5rem;
          height: 600px;
          @media screen and (max-width: 768px) {
            padding: 0 30px;
            text-align: center;
            align-items: center;
            flex-direction: column;
          }
          h4 {
            margin-bottom: 5px;
          }
          h5 {
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 10px;
            color: rgb(119, 119, 119);
            font-size: 0.9rem;
          }
        }
      }
      .getSTartedBtn {
        background-color: rgb(0, 76, 255);
        padding: 8px 35px;
        color: white;
        box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
        margin-bottom: 40px;
        margin-top: 40px;
        &:hover {
          box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
        }
      }
    }
    .featuresWrapper {
      width: 90%;
      box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
      border-radius: 10px;
      padding: 50px 55px;
      @media screen and (max-width: 768px) {
        padding: 50px 40px;
      }
      .featureWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .featureLogo {
          width: 50px;
        }
        .featureText {
          margin-left: 1rem;
          @media screen and (max-width: 768px) {
            margin-left: 1rem;
          }
          font-size: 1rem;
          margin-bottom: 0;
        }
      }
    }
    .questionsWrapper {
      width: 90%;
      border-radius: 10px;
      padding: 50px 55px;
      @media screen and (max-width: 768px) {
        padding: 50px 40px;
      }
      .question {
        font-size: 1.7rem;
      }
      .answer {
        color: rgb(122, 122, 122);
        font-size: 1rem;
      }
    }
    .standerdFeatureWrapper {
      background-image: linear-gradient(47deg,#f9b25e,#f72ba6);
      min-height: 800px;
      margin-top: 3rem;
      .standerdFeatureHeaderImageWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-top: -3rem;
          border: 8px solid white;
          border-radius: 100PX;
        }
      }
      h4 {
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
        color: white;
        margin-top: 15px;
        margin-bottom: 20px;
      }
      .standerdFeatureInnerWrapper {
        width: 90%;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        border-radius: 10px;
        padding: 50px 55px;
        background-color: white;
        margin-top: 35px;
        .col-md-6 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100px;
          text-align: left;
          img {
            width: 30px;
          }
          p {
            margin: 0;
            margin-left: 2rem;
            font-size: 1rem;
          }
        }
      }
    }
    .bottomQuestionsWrapper {
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .getSTartedBtn {
          background-color: rgb(0, 76, 255);
          padding: 8px 35px;
          color: white;
          box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
          margin-bottom: 40px;
          margin-top: 10px;
          &:hover {
            box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
          }
        }
    }
    .greenText {
      padding: 2px;
      background-color: rgb(0, 199, 89);
      border-radius: 60px;
      color: white;
    }
    .priceBeat {
      width: 100%;
      background-color: rgb(231, 247, 255);
      padding: 12px;
      border-radius: 3px;
      margin-top: 18px;
      font-weight: 600;
      color: rgb(0, 86, 206);
    }
</style>
